.page-error .error-mark {
    margin-bottom: 33px; }

.page-error header h1 {
    font-size: 4em;
    font-weight: 400; }

.page-error header p {
    margin-bottom: 30px;
    font-size: 22px;
    text-transform: uppercase; }

.page-error h2 {
    margin-bottom: 30px; }

.page-error .error-advise {
    margin-bottom: 25px;
    color: #A9AFB5; }
